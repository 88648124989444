import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "brief-upload flex flex-col text-center cursor-pointer" }
const _hoisted_2 = { class: "brief-upload__box" }
const _hoisted_3 = { class: "ml-4 md:ml-6 text-pink-400 text-base" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BriefUploadIcon = _resolveComponent("BriefUploadIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        type: "file",
        class: "opacity-0 absolute border top-0 right-0 left-0 bottom-0 w-full cursor-pointer",
        accept: ".xls,.xlsx,.doc,.docx,.pdf,.key",
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.fileSelected && _ctx.fileSelected(...args)))
      }, null, 32),
      _createVNode(_component_BriefUploadIcon),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.fileName || _ctx.text), 1)
    ]),
    (_ctx.fileTooLarge)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: _normalizeClass(["brief-upload__permitted mt-4 md:mt-6 text-base", { '--error': _ctx.fileTooLarge }])
        }, _toDisplayString(_ctx.lang.brief_too_large), 3))
      : _createCommentVNode("", true)
  ]))
}