<template>

    <svg width="24"
         height="24"
         viewBox="0 0 24 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path d="M17.25 23.25C20.5637 23.25 23.25 20.5637 23.25 17.25C23.25 13.9363 20.5637 11.25 17.25 11.25C13.9363 11.25 11.25 13.9363 11.25 17.25C11.25 20.5637 13.9363 23.25 17.25 23.25Z"
              stroke="#C000AF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"/>
        <path d="M17.25 20.25V14.25"
              stroke="#C000AF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"/>
        <path d="M17.25 14.25L15 16.5"
              stroke="#C000AF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"/>
        <path d="M17.25 14.25L19.5 16.5"
              stroke="#C000AF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"/>
        <path d="M3.75 6.75H14.25"
              stroke="#C000AF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"/>
        <path d="M3.75 11.25H9.75"
              stroke="#C000AF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"/>
        <path d="M3.75 15.75H7.5"
              stroke="#C000AF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"/>
        <path d="M7.5 20.25H2.25C1.42157 20.25 0.75 19.5784 0.75 18.75V2.25C0.75 1.42157 1.42157 0.75 2.25 0.75H12.879C13.2765 0.750085 13.6578 0.907982 13.939 1.189L16.811 4.061C17.092 4.3422 17.2499 4.72345 17.25 5.121V7.5"
              stroke="#C000AF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"/>
    </svg>

</template>
