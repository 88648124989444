

    import { defineComponent, ref } from 'vue'
    import { useTranslation } from '../../compositions/UseTranslation'
    import BriefUploadIcon from '../Icons/BriefUploadIcon.vue'

    export default defineComponent({
        name: 'FileUpload',
        components: { BriefUploadIcon },
        props: {
            text: { type: String, required: true }
        },
        setup(properties, { emit }) {

            const fileName = ref('')
            const fileTooLarge = ref(false)
            const { contact } = useTranslation()

            return {
                lang: contact,
                fileName,
                fileTooLarge,
                fileSelected(event: Event & { currentTarget: HTMLInputElement }) {

                    const files = event.currentTarget.files as FileList
                    const MAX_UPLOAD_SIZE = 26214400 // 25mb

                    if (files.length > 0) {

                        for (const file of files) {

                            if (file.size > MAX_UPLOAD_SIZE) {

                                fileTooLarge.value = true

                            } else {

                                fileName.value = file.name
                                emit('selected', file)

                            }

                        }

                    }

                }
            }

        }
    })

